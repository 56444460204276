import React from 'react';
import './styles.css';
import FOLHA from '../../assets/FOLHA.png'

function Soon() {
  return (
    <div className="comingSoon">
      <div className="topText">
        <h1 className="title">EM BREVE</h1>
        <h2 className="subtitle">Mais uma convertedora perto de você</h2>
      </div>      
      <img src={FOLHA} alt="" className="logoSoon" />
    </div>
  )
}

export default Soon;
