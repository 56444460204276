import React, { useEffect, useState } from 'react';
import './styles.css';
import sliderHome1 from '../../assets/sliderHome/sliderHome1.jpg'
import sliderHome2 from '../../assets/sliderHome/sliderHome2.jpg'
import sliderHome3 from '../../assets/sliderHome/sliderHome3.jpg'
import sliderHome4 from '../../assets/sliderHome/sliderHome4.jpg'
import banner1 from '../../assets/banners/banner1.png'

function Home() {

  const [coord, setCoord] = useState({ x: 0, y: 0 })
  const vw =document.documentElement.clientWidth
  
  useEffect(() => {
    const slider = document.getElementById("slider") as HTMLElement
    const sliderW = slider.scrollWidth
    const sliderOffset = (vw - sliderW)
    slider.addEventListener("mousemove", (event) => {
      slider.style.transitionDuration = `0s`
      setCoord({ x: event.pageX, y: event.pageY })
      var scale = ((2 * coord.x )/ vw)
      const parallaxOffset = ((scale - 1) * 0.7*sliderOffset)
      if (scale < 1) {
        slider.style.transform = `translate(${parallaxOffset}px, 0)`
      }
      if (scale > 1) {
        slider.style.transform = `translate(${parallaxOffset}px, 0)`
      }
    })
    slider.addEventListener("mouseleave", (event) => {
      
      setTimeout(() => {
          slider.style.transitionDuration = `1s`
          slider.style.transform = `translate(0 , 0)`
      }, 500)
    })
    window.addEventListener("deviceorientation", (event) => {
      if (event.gamma != null) {
        setCoord({ x: event.gamma, y: 0 })
      }
    })
  }, [coord.x, vw])

  useEffect(() => {
    const caller = document.getElementById("comments") as HTMLElement
    caller.innerHTML = `
    <p> ${coord.x}</p>
    `
  })

  return (

    <div className="home" id="home">
      {/* <Soon /> */}

      <div className="section" id="slider">

        <svg height="300px" width="961px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 961 300" preserveAspectRatio="xMinYMin">
          <polygon points="0,0 657,0 302,300 0,300" className="canvas" fill="url(#sliderHome1)">
            
          </polygon>
          <polygon points="659,0 961,0 961,300 304,300" className="canvas" fill="url(#sliderHome2)"/>

          <defs>
            <pattern id="sliderHome1" patternUnits="userSpaceOnUse" width="961" height="300">
              <image className="svgBG2" href={sliderHome1} x="-304" y="0" width="1100" height="600" />
            </pattern>
            <pattern id="sliderHome2" patternUnits="userSpaceOnUse" width="961" height="300">
              <image className="svgBG" href={sliderHome2} x="0" y="0" width="1100" height="600" />
            </pattern>
          </defs>
        </svg>

        <svg height="300px" width="961px" version="1.1" viewBox="0 0 961 300" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin">
          <polygon points="0,0 657,0 302,300 0,300" className="canvas" fill="url(#sliderHome3)" />
          <polygon points="659,0 961,0 961,300 304,300" className="canvas" fill="url(#sliderHome4)" />
          
          <defs>
            <pattern id="sliderHome3" patternUnits="userSpaceOnUse" width="961" height="300">
              <image className="svgBG2" href={sliderHome3} x="-304" y="0" width="1100" height="600" />
            </pattern>
            <pattern id="sliderHome4" patternUnits="userSpaceOnUse" width="961" height="300">
              <image className="svgBG" href={sliderHome4} x="0" y="0" width="1100" height="600" />
            </pattern>
          </defs>
        </svg>

      </div>

      <img className="section" id="caller" src={banner1} alt="" />
      <div className="section" id="aboutUS">
        <img src={sliderHome3} alt="" />
        <p>
          Dois irmãos que juntos tem décadas de experiência no mercado do GNV.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pellentesque tellus non ligula finibus rhoncus. Vestibulum commodo velit mauris, vitae dictum nunc placerat eu. Nulla imperdiet nisl eu leo dignissim, et laoreet nisl facilisis. Sed rutrum neque in nulla dapibus, in suscipit justo lacinia. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras at est vitae nibh congue convallis eget nec nibh. Proin porttitor vitae arcu fringilla faucibus. Sed fermentum eros vitae ultrices faucibus. Donec vel nunc finibus, vestibulum ipsum ac, mollis erat. Nunc euismod, elit sed sagittis tincidunt, justo risus rutrum tellus, eget dignissim augue nibh commodo nunc. Nunc velit mi, sollicitudin tincidunt massa ac, placerat elementum leo. Vestibulum accumsan id nunc non consectetur. Morbi sed est ac mauris suscipit pharetra. Aliquam sed erat laoreet, scelerisque elit vitae, efficitur arcu.
        </p>
      </div>
      <div className="section" id="comments"></div>
      <div className="section" id="maps"></div>
      <div className="section" id="instaFeed"></div>
    </div>
  );
}

export default Home;