import React from 'react';
import './App.css';
import Header from './components/Header/Header'
import Home from './components/Home/Home'
import Soon from './components/Soon/Soon'
import Footer from './components/Footer/Footer'
import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom';


function App() {
  return (
    <div className="page">
      <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet"></link>
      <Header />
      <Router>
        <Switch>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/">  
              <Soon />
            </Route>
        </Switch>
      </Router>
      <Footer />
    </div>
    
  );
}

export default App;
