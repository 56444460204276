import React from 'react';
import './styles.css';
import { FaInfo, FaHome } from 'react-icons/fa'
import { FiPhoneForwarded, FiMail, FiMenu } from 'react-icons/fi'
import {Link, HashRouter as Router} from 'react-router-dom'
import '@szhsin/react-menu/dist/index.css';
const reactMenu = require('@szhsin/react-menu/dist/index');


function Header() {

  function handleMail() { 
    window.open("http://webmail.gnvmaster.com.br")
  }

  return (
    <div className="header">
      <div className="logo"></div>
      <Router>
        <div className="menuPrincipal">
          <ul>
            <li> <Link to="/home">Home</Link></li>
            <li> <Link to="/">Serviços</Link></li>
            <li><a href="#">Sobre Nós</a></li>
            <li><a href="#">Contato</a></li>
          </ul>
        </div>
      </Router>

      

      <div className="menuPrincipal2">
       <reactMenu.Menu menuButton={
        <button className="menuButton"><FiMenu /></button>
      }>
          <reactMenu.MenuItem><FaHome className="link"/> Home</reactMenu.MenuItem>
          <reactMenu.MenuItem><FaInfo className="link" /> Sobre Nós</reactMenu.MenuItem>
          <reactMenu.MenuItem><FiPhoneForwarded className="link" /> Contato</reactMenu.MenuItem>
          <reactMenu.MenuItem onClick={handleMail}><FiMail className="link" /> Webmail</reactMenu.MenuItem>
      </reactMenu.Menu>
      </div>
      
      <div className="menuSecundario">
        <ul>
          <li><a href="http://webmail.gnvmaster.com.br"><FiMail /></a></li>
        </ul>
      </div>

    </div>
  );
}

export default Header;
