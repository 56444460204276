import React from 'react';
import { IconContext } from "react-icons";
import { FaInstagram, FaFacebook } from 'react-icons/fa'
import { SiGooglemaps } from 'react-icons/si'


import './styles.css';

function Footer() {
  return (
    <div className="footer">
      <div className="space"></div>
      <div className="info">
        <ul>
          {/* <li>INMETRO: 8465189</li>
          <li>CNPJ: 54.988.999/0001-00</li> */}
          <li>AV DOS ESTADOS, 7225 - LOJA 1 - SANTO ANDRÉ / SP</li>
        </ul>
      </div>

      <IconContext.Provider value={{ color: "#e3e3e3", className: "linksHolder"}}>
        <div className="links">
        <FaInstagram />
        <FaFacebook />
        <a href="https://www.google.com/maps/place/-23.651949,+-46.510396/@-23.6519467,-46.5101222,20z"><SiGooglemaps /></a>
        </div>
      </IconContext.Provider>
      
    </div>
  );
}

export default Footer;
